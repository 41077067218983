<template>
  <v-container>
    <v-row>
      <v-col>
        <h1>1.	INHALT UND GELTUNGSBEREICH</h1><br>
        <p>
          Die TyresNParts GmbH (im Folgenden „TyresNParts“, „wir“, „uns“) stellt Großhändlern und Händlern im Automotive Aftermarket (im Folgenden "Lieferanten"), welche über ein Lieferanten-Nutzerkonto bei TyresNParts verfügen, die TyresNParts Plattform (im Folgenden „TNP Marktplatz") zur Verfügung. Der TNP Marktplatz ist eine online Plattform für den Automotive Aftermarket mit hohen Service Standards, die Käufern den Zugang zu einer breiten Produktpalette ermöglicht sowie Services wie z. B. eine standardisierte Rechnungstellung bereitstellt und Lieferanten einen Vertriebskanal für Ihre Produkte sowie Services im Zusammenhang mit dem Vertrieb und der Vermarktung dieser Produkte zur Verfügung stellt.
        </p><br>
        <p>
          Folgende Produkte können unter anderem über den TNP Markplatz erworben werden:
        </p>
        <ul>
          <li>Reifen und Schläuche aller Produktgruppen</li>
          <li>Felgen, Räder (Stahl und Alu) aller Produktgruppen</li>
          <li>Reifendruckkontrollsysteme</li>
          <li>Autoteile und Zubehör</li>
          <li>Branchennahe Zusatzartikel</li>
        </ul> <br>
        <p>
          Für den Zugang, die Nutzung und die Leistungen des TNP Marktplatz gelten die vorliegenden Nutzungsbedingungen in der zum Zeitpunkt der Registrierung jeweils gültigen Fas¬sung, deren Einbezug der Käufer bei Registrierung für den TNP Marktplatz Registrierungsformular zustimmt. Informationen zum Datenschutz sind abrufbar unter www.tyresnparts.com.
          Durch Registrierung des Käufers sowie durch TyresNParts Zustimmung zur Gewährung des Zugangs für einen Käufer wird zwischen dem betreffenden Käufer und TyresNParts eine Vereinbarung geschlossen, die diese Nutzungsbedingungen sowie – zur Vermeidung von Missverständnissen – die gesondert im TNP Marktplatz hinterlegten Allgemeinen Liefer- und Rechnungsbedingungen für auf dem TNP Marktplatz angebotene Produkte umfasst (nachfolgend die „Nutzungsvereinbarung“).
          Wir behalten uns vor, diese Nutzungsvereinbarung einseitig zu ändern soweit dies aufgrund von Änderungen der rechtlichen oder wirtschaftlichen Lage oder aufgrund veränderter technischer Rahmenbedingungen erforderlich ist. Über eine etwaige Änderung werden Sie unter Mitteilung des Inhalts der geänderten Regelungen informieren. Die Änderung wird gültig, wenn Sie nicht binnen zwei Wochen nach Zugang der Änderungsmitteilung uns gegenüber in Schrift-oder Textform widersprechen. Im Fall eines Widerspruchs, behalten wir uns das Recht vor, die Geschäftsbeziehung mit Ihnen zu beenden.
        </p> <br>
        <h1>2.	ZUGANGSBERECHTIGUNG</h1><br>
        <p>
          a)	Der Zugriff und die Nutzung des TNP Marktplatzes erfolgt über www.tyresnparts.com.
          Zugriff und Nutzung unterliegen einer Onlineregistrierung. Hierbei ist der Käufer verpflichtet, die von TyresNParts geforderten Daten anzugeben, die vorliegenden Nutzungsbedingungen vollständig zu akzeptieren. Voraussetzung für den Kauf von Produkten ist zudem die Erteilung eines SEPA-Lastschriftmandats zugunsten der jeweiligen  im TNP Marktplatz vertretenen Lieferanten. Der Käufer stellt sicher, dass die von ihm im Rahmen der Registrierung TyresNParts bereitgestellten Daten wahr und richtig sind und dass er TyresNParts über jegliche Änderungen an diesen Daten unverzüglich informiert.
        </p>
        <p>
          b)	Ein Anspruch auf Erteilung einer Zugangsberechtigung besteht nicht. TyresNParts behält sich vor Erteilung der Zugangsdaten eine Überprüfung des Antragstellers, zum Beispiel hinsichtlich Bonitätsindicies, vor. TyresNParts ist jederzeit berechtigt, die Zugangs-berechtigung des Käufers zu versagen oder zu widerrufen.
        </p>
        <p>
          c)	TyresNParts vergibt dem Käufer nach Prüfung und Genehmigung des Antrags auf Zulassung zum TNP Marktplatz eine User-ID und ein Passwort zur Teilnahme am System. Der Käufer kann über den TNP Marktplatz weitere User-IDs und Passwörter beantragen. Der Käufer bestimmt jeweils einen Verantwortlichen, der zur persönlichen und ausschließ-lichen Nutzung der User-ID und des Passworts berechtigt ist. Die von TyresNParts zugeteilte User-ID sowie das Passwort dürfen nur von derjenigen Person verwendet werden, für welche sie beantragt wurden. Der Käufer stellt den Logout vom System nach jeder Sitzung sicher.
          Der Käufer verpflichtet sich, die User-ID und das Passwort geheim zu halten und nicht an Dritte weiterzugeben. Die Geheimhaltungsverpflichtung gilt für jeden Mitarbeiter des Käufers, der von der User-ID und den Passwörtern Kennt¬nis erhält. In diesem Zusammenhang wird ausdrücklich empfohlen, das Passwort nach Erhalt sofort und im Folgenden regelmäßig zu ändern. Der Käufer verpflichtet sich die Einhaltung der Vertraulichkeit und die ausschließliche Nutzung von des TNP Marktplatzes durch autori-sierte Personen sicher zu stellen. Der Käufer verpflichtet sich, TyresNParts unverzüglich über die unter Ziffer 16 lit. a) aufgeführten Kontaktdaten zu informieren, sobald er Kenntnis darüber erlangt, dass Unbefugte über die User-IDs und/oder Passwörter verfügen. TyresNParts veranlasst in der Folge die Sperrung seines Zugangs zum TNP Marktplatz und bestätigt dies per E-Mail innerhalb von 2 Werktagen.
          Sollte das Passwort mehr als (180) Tage nicht benutzt werden, behält sich TNP die Sperrung des Zugangs vor. Zur Weiterbenut¬zung des TNP Marktplatzes ist die Freischaltung des Passwortes notwendig und bei TyresNParts über die unter Ziffer 16 lit. a) aufgeführten Kontaktdaten zu beantragen
        </p>
        <p>
          d)	Folgen und/oder Schäden, welche durch die Nichtbeach¬tung dieser Bestimmungen entstehen, trägt alleine der Käufer.
        </p><br>

        <h1>3.	NUTZUNG DES TNP MARKTPLATZES</h1><br>
        <p>
          a)	Der TNP Marktplatz stellt lediglich technische Funktionen bereit. Der Käufer ist selbst verpflichtet, die Einhaltung aller seiner rechtlichen Verpflichtungen bei Nutzung des TNP Marktplatzes sicherzustellen.
        </p>
        <p>
          b)	TyresNParts ist grundsätzlich bestrebt, Zugang und Verfügbarkeit des TNP Marktplatzes 24 Stunden täglich an 7 Tagen in der Woche und an jedem Tag im Jahr zu ermöglichen; ausgeschlossen hiervon sind Zeiträume in denen TyresNParts den Zugriff auf den TNP Marktplatz zu War¬tungszwecken, für Updates und/oder aus anderen (Sicherheits-) technischen oder rechtlichen Gründen einschränkt oder unterbricht. TyresNParts ist bestrebt, den Käufer von den entsprechenden Maßnahmen zu unterrichten.
        </p>
        <p>
          c)	TyresNParts steht es frei, den TNP Marktplatz aus technischen, rechtlichen, behördlichen, sicherheitstechnischen oder sonstigen Gründen anzupassen, zu modifizieren und in eigenem Ermessen Funktionen hinzuzufügen oder bestehende Funktionen zu modifizieren oder zu entfernen, sofern die wesentlichen Grundfunktionen des TNP Marktplatzes bstehen bleiben. TyresNParts ist nicht verpflichtet, die Käufer im Voraus über Modifizierungen oder Entfernung von Funktionen zu benachrichtigen.
        </p>
        <p>
          d)	TyresNParts stellt sämtliche in den TNP Marktplatz integrierte Software ohne Zusicherung zur Beschaffenheit, besonderen Verwendungszweck, Fehlerfreiheit, Freiheit von Viren und andere Bedrohungen sowie Kapazität zur Verfügung. TyresNParts kann den uneingeschränkten und fehlerfreien Zugang zum TNP Marktplatz und den über TNP Marktplatz angebotenen Leistungen nicht garantieren
          Der Käufer trägt die mit der Internetnutzung bestehenden Risiken, insbesondere Netzstörungen, Schwankungen bei der Übertragung und Datenverlust. Er stellt sicher, dass er keine Inhalte an den TNP Marktplatz überträgt, die Software, Anwendungen, Programme oder Viren enthalten, bzw. sonstige Daten, die die Funktion der Hard- und Software der Internet-Seiten oder von anderen Nutzern beeinträchtigen oder beschädigen.
        </p>
        <p>
          e)	Die gesamte in TNP Marktplatz integrierte Software, insbesondere alle Arten von maschinenlesbarem Code, Datei- und/oder Bilder¬sammlungen, sind urheberrechtlich und nach anderen Gesetzen zum Schutz des geistigen Eigentums geschützt. Die gesamte Software ist Eigentum von TyresNParts, der jeweiligen Lizenzinhaber oder Dritter. Der Käufer ist während der Dauer der aktiven Registrierung zur Nutzung der in TNP Marktplatz integrierten Software berechtigt vorausgesetzt er hält diese und die ggfs. zusätzlichen Zugangs- und Nutzungsbedingungen der Software ein.  Dem Käufer stehen keine weiteren Nutzungsrechte in Bezug auf die Software zu, inbesondere ist er nicht berechtigt, die Software unterzulizensieren, zu verfielfältigen oder zu verändern.
        </p>
        <p>
          f)	TyresNParts behält sich vor, sämtliche gegen die Gesetze und Vorschriften des Gebiets oder gegen die Bestimmungen der Vereinbarung verstoßende Angebote oder Posts, etc. zu löschen und / oder den Zugang zum TNP Marktplatzzeitweise zu sperren, sofern die Aktitivaten des Käufers dem Ansehen, der Sicherheit und / oder dem ordnungsgemäßen Betrieb des TNP Marktplatz schaden könnten.
        </p>
        <p>
          g)	TyresNParts gibt weder ausdrücklich noch implizierte Zusicherung oder Garantie bezüglich des TNP Marktplatzes, den über den TNP Marktplatz angebotenen Leistungen oder deren Nutzung. Insofern gesetzlich erlaubt sind (hiermit) jegliche ausdrückliche oder impliziere Garantien ausgeschlossen, einschließlich aller Garantien bezüglich der Marktfähigkeit für einen bestimmten Zweck.
        </p>
        <p>
          h)	Der Käufer verpflichtet sich :
        </p>
        <ul>
          <li>den TNP Marktplatz und die auf dem TNP Marktplatz angebotenen Leistungen ausschließlich für eigene Zwecke zu nutzen,</li>
          <li>keine Handlungen vorzunehmen, welche die öffentliche Ordnung, die Rechte von TyresNParts oder die Rechte Driter gefährden und die gegen geltende Gesetze, Vorschriften oder Praktiken verstoßen,</li>
          <li>den TNP Marktplatz und die auf dem TNP Marktplatz angebotenen Leistungen nicht zum Versand unerwünschter Werbenachrichten (« Spam ») zu verwenden,</li>
          <li>keine Daten zum Tweck der Störung des normalen Betriebs des TNP Marktplatzes und der auf dem TNP Marktplatz angebotenen Leistungen zu verbreiten (z.B. « Bots », Viren, etc.),</li>
          <li>in keinster Weise Inhalte hochzuladen, zu posten oder zu übertragen, die Viren, Würmer, Trojaner, Codes oder Skripte enthalten, welche die Integrität oder Sicherheit der Systeme und Daten gefährden könnten, und / oder</li>
          <li>keine in dem TNP Marktplatz und in den auf dem TNP Marktplatz angebotenen Leistungen enthaltene Daten abzuspeichern.</li>
        </ul>
        <p>
          Der Käufer unterlässt:
        </p>
        <ul>
          <li>die Verwendung, Reproduzierung, Modifizierung, Anpassung, Übersetzung, den Download oder die Übertragung der Software als Ganzes oder in Teilen,</li>
          <li>den Verkauf, die Vermietung, die Lizenzgewährung, die Übertragung der Software oder die entgeltlichen und unentgeltliche Gewährung des Zugangs zur Software gegenüber Dritten,</li>
          <li>die Modifizierung, Entfernung oder Verschleierung von Markenzeichen oder Eigentumsvermerken in der Software,</li>
          <li>die Abänderung, Verbesserung, Übersetzung, einschließlich zum Zweck der Fehlerkorrektur, des TNP Marktplatzes und / oder den dazugehärigen Unterlagen,</li>
          <li>die Dekompilierung, Demontage, Entschlüsselung, Extrahierung, Rückentwicklung (Reverse-Engineering) oder Zerlegung der Software oder die Schaffung einer hierauf aufbauenden Website und / oder die Unterstützung anderer Personen bei solchen Tätigkeiten und / oder</li>
          <li>die Nutzung der ihm durch den TNP Marktplatz bekannt gewordenen Postanschriften, Internetadressen, Kontaktdaten und sonstigen Daten von Lieferanten für andere zwecke als der Hinterlegung von Lieferanten im Warenwitschaftssystem und der ordentlichen Ausführung von Kaufverträgen.</li>
        </ul>
        <br>
        <h1>4.	VERGÜTUNG</h1><br>
        <p>
          Die Nutzung des TNP Marktplatzes und der über den TNP Marktplatz bereitgestellten Leistungen ist für den Käufer kostenfrei. Wir behalten uns vor, bei Ausbau der Services die Nutzung dieser in Rechnung zu stellen.
        </p>
        <br>
        <h1>5.	KAUF VON PRODUKTEN – (ANGEBOT UND ANNAHME)</h1><br>
        Der TNP Marktplatz ermöglicht Käufern die Übersicht über die von den Lieferanten für das jeweilige Liefergebiet eingestellten Angebote. Nimmt ein Käufer ein auf Tyres-N-Parts eingestelltes Angebot durch Klick auf die Schaltfläche „jetzt kaufen“ an, so wird hierdurch eine Vereinbarung zwischen dem Lieferanten und dem Käufer geschlossen (nachfolgend ein „Kaufvertrag“).
        Insofern ein Kaufvertrag zustande gekommen ist, darf der Lieferant die Bedingungen des entsprechenden Angebots nicht länger ändern und ist dazu verpflichtet, diese gegenüber dem das Angebot annehmenden Käufer einzuhalten.
        <br>
        <p>
          Dem Kaufvertrag zugrunde liegen
        </p>
        <ul>
          <li>
            die allgemeinen Liefer- und Rechnungsbedingungen für auf dem TNP Marktplatz angebotene Produkte – diese legen die Vorschriften zur Lieferung und Abrechnung für Lieferanten und Käufer fest, die einen Kaufvertrag geschlossen haben - sowie
            die Handelspapiere des Lieferanten einschließlich – soweit durch die Lieferanten wirksam einbezogen - der Allgemeinen Geschäftsbedingungen des jeweiligen Lieferanten. Im Falle von Widersprüchen zwischen diesen Unterlagen gelten stets zuerst die allgemeinen Liefer- und Rechnungsbedingungen.
          </li>
        </ul>
        <br>
        <h1>6.	BEZIEHUNG ZWISCHEN TYRESNPARTS, LIEFERANTEN UND KÄUFERN</h1><br>
        <p>
          TyresNParts ist durch Bereitstellung der Leistungen des TNP Marktplatzes und durch Gewährung des Zugangs zum TNP Marktplatz ausschließlich Dienstleister.
        </p>
        <p>
          TyresNParts ist unter keinen Umständen eine betroffene Partei oder Vertreter, Beauftragter, Makler oder Unterauftragnehmer eines Lieferanten von und / oder in einem zwischen einem Lieferanten und einem Käufer geschlossenen Kaufvertrag.
        </p>
        <p>
          TyresNParts haftet nicht für aus einem Kaufvertrag stammende Ansprüche.
        </p>
        <br>
        <h1>7.	BEWERTUNGEN</h1><br>
        <p>
          Der Käufer erklärt sich damit einverstanden, dass der Lieferant nach einem über den TNP Marktplatz abgewickelten Kauf die mit dem Käufer durchgeführte Transaktion bewerten können und diese Bewertung wörtlich oder sinngemäß als Erfahrungswert für Lieferanten sichtbar gemacht wird. Ist der Käufer der Auffassung, dass die Inhalte einer ihn betreffenden Bewertung seine Persönlichkeits-, Immaterialgüterrechte ode reine sonstige Rechtsposition verletzen, kann er dies gegenüber TyresNParts unter info@tyresnparts.com unter hinreichender Spezifizierung mitteilen. Wir behalten uns das Recht vor, bei hinreichend konkreter Mitteilung nach Prüfung und gegebenenfalls Anhörung des Lieferanten die betroffene Bewertungen zu entfernen oder bearbeiten.
        </p><p>
          Soweit der Käufer seiterseits nach abgewickeltem Kauf eine Bewertung des Lieferanten vornimmt, ist diese sachlich zu halten und hat sich inhaltlich ausschließlich auf die Abwicklung des jeweils konkret zu Grunde liegenden Erwerbsvorgangs zu beziehen. Dem Käufer ist es untersagt, Äußerungen und / oder Bewertungen einzustellen, die unwahr, beleidigend, herabsetzend oder verleumderisch sind und / oder die Schmähkritik enthalten.
          Wir behalten uns vor, die vom Käufer eingetellten Inhalte, insbesondere Bewertungen, zu löschen, wenn konkrete Anhaltspunkte für einen Verstoß gegen diese Nutzungsbedingungen oder geltendes Recht bestehen.
        </p><p>
          Der Käufer stellt TyresNParts von allen Ansprüchen Dritter frei, die gegen TyresNParts geltend gemacht werden, soweit diese aus der von dem Käufer abgegebenen Bewertung resultieren oder hierzu im Zusammenhang stehen. Der Käufer verpflichtet sich weiter, auch die in diesem Zusammenhang der TyresNParts entstehenden Kosten einer zweckmäßigen Rechtsverteidigung einschließlich sämtlicher Gerichts- und Anwaltskosten zu tragen, soweit der Käufer die Rechtsverletzung zu vertreten hat. Eine entsprechende Freistellung erfolgt, soweit Ansprüche Dritter gegen Maßnahmen von TyresNParts geltend gemacht werden, die auf ein unberechtigtes Löschungsverlangen des Käufers zurückzuführen sind.
        </p><p>
          TyresNPartsTyresNPartsTyresNParts übernimmt gegenüber dem Käufer keine Haftung für maßnahmen, die von TyresNParts im zusammenhang mit Bewertungen des Käufers oder von Lieferanten getroffen wurden.
        </p><br>
        <h1>8.	URHEBER UND SONSTIGE RECHTE VON TYRESNPARTS</h1><br>
        <p>
          TNP Marktplatz, die über den TNP Marktplatz angebotene Leistungen sowie die von TyresNParts bereitgestellten Inhalte, insbesondere Texte, Bilder, Animationen, Videos, Musik, Geräusche und andere Materialien unterliegen dem Urheberrecht und anderen Gesetzen zum Schutz des geistigen Eigentums und sind jeweils als Ganzes sowie in Teilen urheberrechtlich / markenrechtlich geschützt. Sie stehen im Eigentum von TyresNParts oder sind durch Dritte für den Gebrauch durch TyresNParts lizenziert.
          Dementsprechend ist/sind TyresNParts oder deren Geschäftspartner der/die Eigentümer aller Geistigen Eigentumsrechte an der Struktur und am Inhalt des TNP Marktplatzes, der über den TNP Marktplatz angebotenen Leistungen und der zugehörigen Dienste.
          Durch die Vereinbarung werden dem Käufer weder während der Laufzeit der Vereinbarung noch nach deren Ablauf jegliche Geistigen Eigentumsrechte an dem TNP Marktplatz, den über den TNP Marktplatz angebotenen Leistungen (einschließlich insbesondere deren Funktionen, Programme, Algorithmen, Tools, Quellcodes, Daten, etc.), an der Warenmarke „TYRESNPARTS“ und sonstigen registrierten Warenmarken von TyresNParts sowie sonstige Geistigen Eigentumsrechte von TyresNParts übertragen. Die Nutzung von nicht durch TyresNParts autorisierten automatischen oder elektronischen Werkzeugen zum Zwecke des Zugangs zum TNP Marktplatz und/oder der Nutzung der über den TNP Marktplatz bereitgestellten Leistungen oder der Beschaffung oder des Herunterladens des gesamten Inhalts oder eines Teils dieses Inhalts des TNP Marktplatzes ist strengstens untersagt. Gemäß der Vereinbarung sind Käufer berechtigt, unter der ausschließlichen Verwendung der Such- und Drucktools und -funktionen des TNP Marktplatzes die bei ihm sichtbaren Daten zum Zwecke der fortlaufenden Sichtung auszudrucken.
          Jegliche unautorisierte Nutzung kann zivil- und/oder strafrechtlich belangt werden und zur Zahlung von Schadensersatz führen
        </p><br>
        <h1>9.	HAFTUNG</h1>
        <p>
          a)	Haftung im Zusammenhang mit der Nutzung des TNP Marktplatzes
          TyresNParts haftet nicht im Zusammenhang mit dem TNP Marktplatz. Das gilt nicht für Schadensersatzansprüche aufgrund einer Verletzung des Lebens, des Körpers, der Gesundheit und Schadensersatzansprüche aus der Verletzung wesentlicher Vertragspflichten (Kardinalspflichten) sowie die Haftung für sonstige Schäden, die auf einer vorsätzlichen oder grob fahrlässigen Pflichtverletzung von TyresNParts, seiner gesetzlichen Vertreter oder Erfüllungsgehilfen beruhen. Wesentliche Vertragspflichten sind solche, deren Erfüllung zur Erreichung des Ziels des Vertrages notwendig ist.
          Bei einfacher Fahrlässigkeit haftet TyresNParts nur bei Verletzung
          vertragswesentlicher Pflichten. In diesen Fällen ist die Haftung auf die Höhe des
          vorhersehbaren Schadens, mit dessen Eintritt bei Verträgen der vorliegenden Art
          typischerweise gerechnet werden musste, beschränkt. Als vertragswesentliche Pflicht
          im vorgenannten Sinn ist eine solche Pflicht zu verstehen, die für die Erreichung des
          Vertragszwecks wesentlich ist oder deren Erfüllung die ordnungsgemäße
          Durchführung des Vertrags erst ermöglicht und auf deren Einhaltung der Auftraggeber
          regelmäßig vertrauen darf. Der vorhersehbare, vertragstypische Schaden ist ein
          solcher, der bei einem üblichen Schadensverlauf typischerweise zu erwarten ist. TyresNParts haftet nicht für Unterbrechungen des TNP Marktplatzes, für Datenverfälschungen oder -verluste, den Ausfall von erwarteten Einsparungen oder für Umsatz- oder Gewinnausfälle.
        </p><p>
          Der TNP Marktplatz kann einfache Links oder „Deep Links“ zu anderen Webseiten der Michelin Gruppe oder Webseiten Dritter enthalten, die unabhängig betrieben werden und ggfs. eigene Nutzungsbedingungen enthalten. „Deep Links“ setzen die Genehmigung des Dritten voraus. Diese Hyperlinks dienen ausschließlich der Verein-fachung des Webseitenbesuchs und gelten nicht als Zustimmung von TyresNParts zu den Inhalten dieser Webseiten. TyresNParts hat keinerlei Kontrolle über diese Webseiten und ist demzufolge weder für die Erreichbarkeit, Relevanz sowie Verfügbarkeit dieser Webseiten noch für die darauf enthaltene/n Inhalte, Werbung, Produkte und/oder verfügbaren Services verantwortlich und haftbar.
        </p><p>
          TyresNParts nutzt von Dritten bereitgestellte Artikeldaten. TyresNParts hat keine Kontrolle über diese Artikeldaten und ist demzufolge nicht für die Richtigkeit, Vollständigkeit und Aktualität verantwortlich und haftbar.
        </p><p>
          Der Käufer haftet für alle in seinem Namen unter Verwendung seiner Benutzernamen und Passwörter durchgeführten Aktivitäten sowie für jede Nutzung seines Kontos sowie für die die Erfüllung der in Ziffer 3 definierten Verpflichtungen durch die von ihm bestimmten Benutzer.
          <br>
        </p><p>
          b)	Haftung gemäß Kaufvertrag
          TyresNParts haftet nicht
        </p>
        <ul>
          <li>aufgrund eines in dem TNP Marktplatz durch einen Lieferanten eingestellten Angebots – hier kann ausschließlich der Lieferant haftbar gemacht werden,</li>
          <li>aufgrund verspäteter Produktlieferungen, vom Angebot abweichender Lieferungen oder gegen geltende Gesetze und Vorschriften des Gebiets verstoßende Lieferungen – hier kann ausschließlich der Lieferant haftbar gemacht werden,</li>
          <li>aufgrund der Nichtvornahme von Zahlungen oder ausschließlicher Teilzahlungen für Produkte gemäß Kaufvertrag – hier kann ausschließlich der Käufer haftbar gemacht werden,</li>
          <li>aufgrund der Nutzung der Onlinedienste durch einen Lieferanten und/oder einen Käufer und/oder einen von diesen bestimmten Benutzer – für die Nutzung kann je nach Fall ausschließlich der Lieferant bzw. der Käufer haftbar gemacht werden sowie</li>
          <li>im Allgemeinen und ungeachtet des Grundes aufgrund eines Streitfalls zwischen einem Lieferanten und einem Käufer in Zusammenhang mit dem Angebot eines Lieferanten, mit einem Kaufvertrag oder aufgrund unangebrachter Verhaltensweisen oder Handlungen (und deren Folgen) des Käufers und/oder seiner Vertreter</li>
        </ul>
        <p>
          c)	Lit. a) bis c) gelten auch für die persönliche Haftung der gesetzlichen Vertreter und der Mitarbeiter von TyresNParts sowie für die sonstigen Erfüllungsgehilfen.
        </p><p>
          d)	Der Käufer schützt TyresNParts (und dessen leitende Angestellte, Geschäftsführer, Mitarbeiter und Beauftragte) und verpflichtet sich dementsprechend, TyresNParts von Forderungen und/oder Klagen vonseiten jeglicher Käufer, Lieferanten oder sonstiger Personen sowie von sich aus diesen gegen TyresNParts gerichteten Forderungen/Klagen ergebenden Verlusten, Schäden, Zahlungen, Kosten, Ausgaben und sonstigen Kostenpunkten (einschließlich Anwaltskosten) freizustellen und schadlos zu halten; dies umfasst Forderungen/Klagen:
        </p>
        <ul>
          <li>in Zusammenhang mit einem Angebot,</li>
          <li>in Zusammenhang mit einem Kaufvertrag,</li>
          <li>vonseiten eines Käufers/Lieferanten oder seiner Vertreter oder von ihm bestimmter Benutzer,</li>
          <li>in Zusammenhang mit einem Produkt,</li>
          <li>in Zusammenhang mit der Bereitstellung falscher Daten während der Registrierung für den TNP Marktplatz oder dem Unterlassen der Aktualisierung der entsprechenden Daten,</li>
          <li>in Zusammenhang mit der Nutzung des Kontos des Käufers einschließlich der unautorisierten Nutzung des Kontos des Käufers durch Dritte,</li>
          <li>in Zusammenhang mit der Nichteinhaltung und/oder der Nichterfüllung rechtlicher oder behördlicher Verpflichtungen, einschließlich Steuerverpflichtungen sowie</li>
          <li>in Zusammenhang mit der Verletzung Geistiger Eigentumsrechte von TyresNParts oder Dritten.</li>
        </ul>
        <p>
          e)	Durch die Annahme dieser Allgemeinen Nutzungsbedingungen bestätigen die Käufer, dass die Sperrung ihres Zugangs zum TNP Marktplatz oder ihr Unvermögen, sich mit dem TNP Marktplatz zu verbinden und/oder diesen zu nutzen, sie ungeachtet der Ursache nicht zur Geltendmachung von Haftungs- oder Schadensersatzansprüchen gegenüber TyresNParts berechtigt.
        </p><p>
          f)	Keine der Parteien kann für die Nichterfüllung oder die verzögerte Erfüllung der Vereinbarung haftbar gemacht werden, insofern diese aufgrund eines Falls von höherer Gewalt zustande kam, der außerhalb der Kontrolle der sich auf höhere Gewalt berufenden Partei lag, beim Abschluss der Vereinbarung nicht vorhersehbar war und dessen Auswirkungen durch vertretbare Maßnahmen nicht hätten abgewendet werden können. Dauert der Fall höherer Gewalt länger als 60 Tage an, so ist die nicht von höherer Gewalt betroffene Partei zur fristlosen Kündigung der Vereinbarung nach Mitteilung an die andere Partei berechtigt.
        </p><br>
        <h1>10.	VERSICHERUNG</h1><br>
        <p>
          Durch Annahme dieser Allgemeinen Nutzungsbedingungen bestätigt der Käufer, dass er bei seriösen und solventen Versicherungsunternehmen Haftpflichtversicherungen mit zur Abdeckung aller mit seinen Geschäftsaktivitäten einhergehenden Risiken ausreichenden Versicherungssummen abgeschlossen hat und verpflichtet sich, besagte Versicherungspolicen für die Laufzeit der Vereinbarung aufrechtzuerhalten und TyresNParts auf erste Nachfrage eine Bescheinigung zum Nachweis der Gültigkeit besagter Versicherungspolicen zur Verfügung zu stellen.
        </p>
        <br>
        <h1>11.	GEHEIMHALTUNG UND VERTRAULICHKEIT</h1><br>
        <p>
          a)	Die von uns dem Käufer im Rahmen der Durchführung der Nutzungsvereinbarung mitgeteilten oder zur Kenntnis gelangten Informationen, Erkenntnisse, Ergebnisse, Daten und Unterlagen (im Folgenden als „Geheimhaltungsbedürftige Informationen“ bezeichnet) unterliegen der Geheimhaltung, unabhängig davon, wie diese verkörpert, auf welche Art und Weise die Weitergabe oder die Kenntnisnahme erfolgt (z.B. auch per unverschlüsselter E-Mail) oder ob sie ausdrücklich als geheimhaltungsbedürftig (z.B. "vertraulich" oder "geheim") gekennzeichnet sind.
        </p><p>
          b)	Dazu gehören insbesondere
        </p>
        <ul>
          <li>Know-how, Schutzrechte, Source Code und sonstiges geistiges Eigentum bzw. sonstige Arbeitsergebnisse, welche im Rahmen des Vertrages und/oder dessen Durchführung weitergegeben werden,</li>
          <li>andere, nicht öffentlich zugängliche Informationen, die die jeweilige Partei im Rahmen des Vertrags und/oder dessen Durchführung erlangt.</li>
        </ul>
        <p>
          c)	Der Käufer ist verpflichtet, die Geheimhaltungsbedürftigen Informationen im Sinne eines Geschäftsgeheimnisses nach dem GeschGehG geheim zu halten und nur für Zwecke dieser Nutzungsvereinbarung und deren Durchführung sowie nur in den in dieser Nutzungsvereinbarung vorgesehenen Grenzen zu verwenden. Ihre unternehmensinterne Offenlegung ist auf das für die Durchführung der Nutzungsvereinbarung erforderliche Maß zu beschränken ("need-to-know"). Das Recht auf Rückentwicklung nach dem GeschGehG ist ausgeschlossen.
        </p><p>
          d)	Der Käufert hat angemessene technische und organisatorische Maßnahmen zu treffen, um zu verhindern, dass Geheimhaltungsbedürftige Informationen Dritten zugänglich werden.
        </p><p>
          e)	Der Käufer hat sicherzustellen, dass seine Angestellten und Personen, die in Kenntnis der Geheimhaltungsbedürftigen Informationen kommen, entsprechend der vorliegenden Regelungen zur Geheimhaltung verpflichtet sind. Im Rahmen der gesetzlichen Möglichkeiten werden diese Pflichten auch für die Zeit nach dem Ausscheiden den Mitarbeitern der jeweiligen Partei auferlegt.
        </p><p>
          f)	Die Pflicht zur Geheimhaltung und Nichtverwertung der Geheimhaltungsbedürftigen Informationen entfällt, soweit diese
        </p>
        <ul>
          <li>• dem Käufer vor der Mitteilung nachweislich bekannt waren oder</li>
          <li>• der Öffentlichkeit vor der Mitteilung bekannt oder allgemein zugänglich waren oder nach der Mitteilung ohne Verschulden des Lieferanten bekannt werden oder</li>
          <li>• im Wesentlichen Informationen entsprechen, die dem Käufer zu irgendeinem Zeitpunkt von einem berechtigten Dritten offenbart oder zugänglich gemacht wurden.</li>
        </ul>
        <p>
          g)	Die Geheimhaltungspflichten bleiben nach Ende der Laufzeit des Vertrags, unabhängig von der Art der Beendigung, bis zum Ablauf von weiteren drei (3) Jahren in Kraft.
        </p><br>
        <h1>12.	DATENSCHUTZ</h1><br>
        <p>
          TyresNParts GmbH erhebt und verarbeitet personenbezogene Daten nach den Grundsätzen und auf Grundlage der DS-GVO sowie des BDSG. Personenbezogene Daten, die TyreNParts im Zusammenhang mit der Gewährung eines Benutzerzugangs zum TNP Marktplatz zur Kenntnis gelangen, werden daher ausschließlich im Rahmen der festgelegten Zwecke und zur Abwicklung des Vertragsverhältnisses von TyresNParts genutzt.
        </p>
        <p>
          Informationen zur Verarbeitung personenbezogener Daten durch die verantwortlichen Stelle (TyresNParts GmbH) finden sich unter: www.tyresnparts.com.
        </p>
        <p>
          Sofern TyresNParts personenbezogene Daten im Auftrag des Lieferanten verarbeitet, schließen die Parteien eine gesonderte Vereinbarung zur Auftragsverarbeitung.
        </p>
        <br>
        <h1>13.	ETHIK UND COMPLIANCE</h1><br>
        <p>
          a)	Der Käufer erklärt im Rahmen der Geschäftsbeziehung jeglicher Form von Bestechung und Korruption entgegenzuwirken und die dahingehenden gesetzlichen Bestimmungen einzuhalten.
        </p>
        <p>
          b)	Der Käufer verpflichtet sich und bestätigt insbesondere Folgendes zu unterlassen:
        </p>
        <ul>
          <li>unseren Mitarbeitern, die mit der Vorbereitung, dem Abschluss oder der Durchführung des Vertrages oder Lieferbeziehung betraut sind, oder ihnen nahestehenden Personen Geschenke, andere Zuwendungen oder sonstige unangemessene finanzielle oder andere Vorteile unmittelbar oder mittelbar in Aussicht zu stellen, anzubieten, zu versprechen oder zu gewähren,</li>
          <li>strafbare Handlungen zu begehen oder Beihilfe zu leisten, die unter § 298 StGB (Wettbewerbsbeschränkende Absprachen bei Ausschreibungen), § 299 StGB (Bestechlichkeit und Bestechung im geschäftlichen Verkehr), § 333 StGB (Vorteilsgewährung), § 334 StGB (Bestechung), § 17 UWG (Verrat von Geschäfts- und Betriebsgeheimnissen) oder § 18 UWG (Verwertung von Vorlagen) fallen.</li>
        </ul>
        <p>
          Die oben genannten Verpflichtungen gelten auch für alle Tochtergesellschaften, Mitarbeiter, Direktoren, Arbeitnehmer oder Amtsträger des Käufers sowie für alle im Rahmen der Vertragsbeziehung beteiligten Dritten.
        </p>
        <p>
          c)	Bei einem Verstoß gegen die in lit. b) genannten Verpflichtungen sind wir unbeschadet sonstiger Kündigungs- und Rücktrittsrechte berechtigt, den Vertrag außerordentlich zu kündigen und sämtliche Verhandlungen abzubrechen.
        </p>
        <p>
          d)	Alle Schäden, die uns aus einem Verstoß gegen die in Absatz 2 genannten Verpflichtungen entstehen und vom Käufer zu vertreten sind, hat der Käufer uns zu ersetzen.
        </p>
        <p>
          <br>
        </p><h1>14.	LAUFZEIT UND KÜNDIGUNG</h1><br>
        <p>
          Die Vereinbarung wird auf unbestimmte Zeit geschlossen und tritt zu dem Datum in Kraft, an dem der Käufer seine(n) ersten Benutzernamen und sein(e) erstes/ersten Passwort/Passwörter von TyresNParts erhält.
          Der Käufer ist daraufhin berechtigt, die Vereinbarung jederzeit ohne Angabe von Gründen unter Einhaltung einer Kündigungsfrist von 3 Monaten zum Monatsende durch Übersendung einer E-Mail an TyresNParts an die folgende Adresse zu kündigen: info@tyresnparts.com Gleichermaßen ist TyresNParts berechtigt, die Vereinbarung jederzeit ohne Angabe von Gründen ebenfalls unter Einhaltung einer Kündigungsfrist von 3 Monaten zum Monatsende durch Übersendung einer E-Mail an eine vom Käufer im Zuge der TNP Marktplatz -Registrierung bereitgestellte Adresse zu kündigen.
          Verstößt eine der Parteien gegen eine ihrer Verpflichtungen aus der Vereinbarung, so steht es der anderen Partei frei, der verstoßenden Partei innerhalb eines Zeitraums von 14 Tagen mittels E-Mail eine formale Aufforderung zur Behebung des Verstoßes zukommen zu lassen. Wird der Verstoß nicht innerhalb des gewährten Zeitraums von 14 Tagen behoben, so ist die andere Partei berechtigt, die Vereinbarung gegenüber der verstoßenden Partei unbeschadet der anderen Partei möglicherweise zustehenden Schadensersatzansprüche vollständig und fristlos mittels E-Mail zu kündigen. Verstöße gegen die Vereinbarung gemäß diesem Paragrafen sind unter anderem:
        </p>
        <ul>
          <li>die Nichteinhaltung der in Anhang 1 der Vereinbarung aufgeführten allgemeinen Liefer- und Rechnungsbedingungen für auf dem TNP Marktplatz angebotene Produkte;</li>
          <li>die Bereitstellung falscher Daten durch den Käufer während der Registrierung für den TNP Marktplatz;</li>
          <li>die Nichteinhaltung durch den Käufer der Bestimmungen von Ziffer 11.</li>
        </ul>
        <p>
          Abweichend von vorstehendem Paragrafen ist TyresNParts im Falle eines schweren Verstoßes gegen die Vereinbarung und sofern unbedingt notwendig dazu berechtigt, die Vereinbarung formfrei und ohne formale Aufforderung oder Einräumung einer Kündigungsfrist mittels E-Mail an eine vom Käufer im Zuge der TNP Marktplatz Registrierung bereitgestellte Adresse zu kündigen. Schwere Verstöße gegen die Vereinbarung gemäß diesem Paragrafen sind unter anderem:
        </p>
        <ul>
          <li>die Nichtvornahme der Zahlung oder die ausschließliche Vornahme von Teilzahlungen ohne gerechtfertigten Grund bei mehr als zwei von einem Lieferanten ausgestellten Rechnungen innerhalb eines Zeitraums von 6 aufeinanderfolgenden Monaten;</li>
          <li>die Offenlegung der Benutzernamen und/oder Passwörter des Käufers gegenüber Dritten druch den Käufer;</li>
          <li>die Verwendung durch den Käufer von ihm durch die Nutzung des TNP Marktplatzes bekannt gewordenen personenbezogenen Daten von Lieferanten und Käufern für andere Zwecke als der ordentlichen Ausführung eines Kaufvertrags;</li>
          <li>die Nichteinhaltung durch den Käufer der in Ziffer 3 der Vereinbarung festgelegten Vorschriften und Verpflichtungen;</li>
          <li>die Nichteinhaltung der in Ziffer 13 „Übertragung von Rechten“ festgelegten Vorschriften;</li>
          <li>die Missachtung der Geistigen Eigentumsrechte von TyresNParts oder dessen Geschäftspartnern, insbesondere mittels der Nutzung von nicht durch TyresNParts autorisierten automatischen oder elektronischen Werkzeugen zum Zwecke des Zugangs zum TNP Marktplatz und/oder der Nutzung der über den TNP Marktplatz bereitgestellten Leistungen oder der Beschaffung oder des Herunterladens des gesamten Inhalts oder eines Teils dieses Inhalts des TNP Marktplatzes.</li>
        </ul>
        <p>
          Wird TyresNParts oder der Käufer für insolvent erklärt oder wird ein Kollektivverfahren gegen eine der Parteien eröffnet, so wird die Vereinbarung unter Berücksichtigung einschlägiger Bestimmungen automatisch und unverzüglich gekündigt.
          Nach Ablauf der Vereinbarung sind der Käufer und die von ihm bestimmten Benutzer ungeachtet des Ablaufgrundes nicht länger berechtigt, auf den TNP Marktplatz oder die über den TNP Marktplatz bereitgestellten Leistungen zuzugreifen; der Käufer hat ab diesem Zeitpunkt keinen Zugriff mehr auf seine Daten. Es liegt daher in der Verantwortung des Käufers, die diesbezüglich erforderlichen Maßnahmen zu ergreifen.
        </p>
        <br>
        <h1>15.	ÜBERTRAGUNG VON RECHTEN</h1><br>
        <p>
          Der Zugang zum TNP Marktplatz wird ausdrücklich dem Käufer gewährt. Weder die sich aus der Zustimmung zu den vorliegenden Nutzungsbedingungen ergebende Vereinbarung selbst noch jegliche aus dieser erwachsende Rechte oder Ansprüche des Käufers sind übertragbar oder dürfen ohne vorherige Zustimmung durch TyresNParts übertragen werden; es liegt im Ermessen von TyresNParts, eine solche Zustimmung zu erteilen oder zu verweigern. Eine nicht von TyresNPart autorisierte Übertragung ist null und nichtig. Ein Verstoß gegen die vorliegende Regelung stellt einen schweren Verstoß gegen die Nutzungsbedingungen dar und berechtigt TyresNParts, diese nach Mitteilung an den Käufer rechtmäßig zu kündigen. TyresNParts steht es frei, die die sich aus der Zustimmung zu den vorliegenden Nutzungsbedingungen ergebende Vereinbarung an jegliche mit TyresNParts im aktienrechtlichen Sinne verbundenen Unternehmen zu übertragen.
        </p>
        <br>
        <h1>16.	SONSTIGE BESTIMMUNGEN</h1><br>
        <p>
          a)	Jegliche Rückfragen des Käufers an TyresNParts bezüglich der Funktionsweise des TNP Marktplatzes sind an die folgende e-Mail Adresse zu richten: info@tyresnparts.com
          TyresNParts ist bestrebt dem Käufer zu antworten.
        </p>
        <p>
          b)	Sollten sich einzelne Bestimmungen der vorliegenden Nutzungsbedingungen als unwirksam erweisen, so steht dies ihrer Wirksamkeit im Übrigen nicht entgegen. Anstelle der unwirksamen Bestimmun¬gen, wird eine Regelung getroffen, die dieser in ihrem rechtlichen und wirtschaftlichen Gehalt am Nächsten kommt.
        </p><p>
          c)	Erfüllungsort und ausschließlicher Gerichtsstand für Ansprüche aus dieser Geschäftsbeziehung ist Frankfurt am Main. Es findet ausschließlich deutsches Recht unter Ausschluss des UN-Kaufrechts (CISG) Anwendung.
        </p><p>
          d)	Im Falle von Unklarheiten oder Widersprüchen unter den verschiedenen Nutzungsbedingungen (deutsch und englisch) gilt der deutsche Originaltext.
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {

};
</script>

<style scoped>
    ul {
        list-style: disc;
    }
</style>
